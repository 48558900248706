@import 'styles/shared.scss';

/*
  ------------------------------------------------------------
  Footer - defaults
  ------------------------------------------------------------
*/

.footer {
    display: flex;
    padding : 30px 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  
    h5 {
        margin-top: 0px;
    }

    &_item {
        &-logo {
            display: none;

            svg {
                height: 50px;
            }
        }

        &-vertical-logo {
            display: block;

            svg {
                height: 100px;
            }
        }

        &_banner {
            display: flex;
            flex-wrap: wrap;
            
            &-iksiegowosc24 {
                margin: 30px 15px 0 0;

                img {
                    border-radius: 10px;
                }
            }

            &-commarch {
                margin: 30px 15px 0 0;

                svg {
                    height: 50px;
                    border-radius: 10px;
                }
            }

            justify-content: center;
        }
    }
}


/*
  ------------------------------------------------------------
  Footer - tablets
  ------------------------------------------------------------
*/

@media only screen and (min-width: 576px) {
    .footer {
        margin: 0 -15px;
        flex-direction: row;
        align-items: flex-start;

        &_item {
            &-vertical-logo {
                display: none;
            }

            &-logo {
                display: block;
            }
            
            &_banner {
                margin-top: 3px;
            }
        }
    }
}