@import 'styles/shared.scss';

/*
  ------------------------------------------------------------
  Defaults
  ------------------------------------------------------------
*/
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &-dialog {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        position: fixed;
    }
  
    &-content {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
        position: absolute;
        border: 2px solid $primary-color;
    }
    
    &-title {
        font-size: 21px;
        color: $light-color !important;  
        font-family: $heading-font-family;
    }
    
    &-body {
        position: absolute;
        top: 60px;
        bottom: 0px;
        width: 100%;
        font-weight: 300;
        padding: 15px 0px;
        overflow-y: overlay;
    }

    &-header {
        top: 0;
        right: 0;
        left: 0;
        border: 0;  
        padding: 5px 0px;
        height: 60px;
        position: absolute;
        box-shadow : 0 0 15px rgba($black-color, 0.2);

        .container {
            height: 100%;
            display: flex;
            align-items: center;

            svg {
                width: 40px;
                margin-right: 15px;
            }

            .close {
                outline: 0;
                opacity: 0.8;
                margin: 0px 0px auto auto;
                font-size: 40px;
                color: $light-color;

                &:hover, &:focus, &:active {
                    opacity: 1;
                    box-shadow: none;
                    background-color: transparent;
                }


            }
        }
    }
}