@import-normalize;
@import "~animate.css/animate.css";
@import "styles/shared.scss";
@import "~font-awesome/css/font-awesome.min.css";

/*
  ------------------------------------------------------------
  Fonts
  ------------------------------------------------------------
*/

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Lora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/Lora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/Lora-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  font-style: italic;
  font-weight: 700;
  src: url("fonts/Lora-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "2-questa_grande_regular_31";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/2-questa_grande_regular_31-webfont.woff") format("woff"),
    url("fonts/2-questa_grande_regular_31-webfont.woff2") format("woff2");
}

/*
  ------------------------------------------------------------
  Defaults
  ------------------------------------------------------------
*/

/*
    Defaults (from Bootstrap):
    - font-size: 10px;
    - line-height: 1.42857143;
*/

html {
  height: 100%;
  overflow-x: auto;
}

body {
  min-width: 320px;
  font-size: 100%;
  height: 100%;
  color: $dark-color;
  position: relative;
  background-color: $light-color;
  font-family: $default-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 3.6em;
}

h3 {
  font-size: 3.2em;
}

h4 {
  font-size: 2.8em;
}

h5 {
  font-size: 2.4em;
}

p {
  font-size: 1.8em;
  line-height: 1.6;
  margin: 0 0 30px 0;
}

a:link {
  color: $primary-color;
  text-decoration: none;
}

a:visited {
  color: $primary-color;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.fn-default {
  font-family: $default-font-family;
}

.fn-heading {
  font-family: $heading-font-family;
}

hr.light,
hr.primary {
  max-width: 100px;
  margin: 25px auto;
  border-bottom: none;
}

hr.light {
  border: 2px solid $light-color;
}

hr.primary {
  border: 2px solid transparent;
  border-image: $primary-gradient;
  border-image-slice: 1 fill;
}

.bg-light {
  background: $light-color !important;
  color: $dark-color !important;
}

.bg-dark {
  background: $dark-color !important;
  color: $light-color !important;
}

.bg-lighter {
  background: $lighter-color !important;
  color: $dark-color !important;
}

.bg-darker {
  background: $darker-color !important;
  color: $light-color !important;
}

.bg-primary {
  background: $primary-color !important;
  color: $light-color !important;
}

.bg-purple {
  background: $purple-color !important;
  color: #1f4e7c !important;
}

.container {
  margin-top: -1px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-indicators {
  .active {
    background-color: $dark-color;
  }

  li {
    margin: 0px;
    width: 12px;
    height: 12px;
    border-color: $dark-color;

    &:hover {
      background-color: $dark-color;
    }
  }
}

/*
  ------------------------------------------------------------
  Tablets
  ------------------------------------------------------------
*/

@media only screen and (max-width: 767px) {
  body {
    font-size: 90%;
  }

  p {
    margin: 0 0 20px 0;
  }

  hr.light,
  hr.primary {
    margin: 20px auto;
  }
}

/*
  ------------------------------------------------------------
  Mobiles
  ------------------------------------------------------------
*/

@media only screen and (max-width: 480px) {
  body {
    font-size: 80%;
  }
}
