@import "styles/shared.scss";

/*
  ------------------------------------------------------------
  Menu - defaults
  ------------------------------------------------------------
*/

.menu {
  padding: 20px 0px;
  border: 0;
  min-height: 50px;
  transition: all 0.7s;
  background: transparent;
  background-color: rgba($light-color, 0.9);
  box-shadow: 0 0 15px rgba($black-color, 0.2);

  &_brand {
    padding: 0px;
    font-size: unset;
  }

  &_nav {
    li {
      a {
        font-size: 2.1em;
        transition: color 0.3s;
        background: transparent;
        text-transform: capitalize;
        color: $black-color !important;
        font-family: $heading-font-family;
      }
    }
  }
}

/*
  ---------------------------------------------------
  Menu - desktops
  ---------------------------------------------------
*/

@media only screen and (min-width: 768px) {
  .menu {
    &-collapsed {
      padding: 5px 0px;
      background: $light-color;
    }

    &_nav {
      .nav {
        animation-delay: 250ms;
        animation-duration: 3000ms;
      }

      li {
        a {
          &::after {
            right: 0;
            left: 0;
            bottom: 0;
            height: 3px;
            opacity: 0;
            content: "";
            position: absolute;
            width: 60%;
            margin: 0 auto;
            background: $primary-gradient;
            transform: translateY(10px);
            transition: opacity 0.3s, transform 0.3s;
          }

          &:focus {
            background: transparent;
          }

          &:hover {
            background: transparent;

            &::after {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
      }

      li.active a {
        color: $primary-color;
      }
    }
  }
}

/*
  ---------------------------------------------------
  Menu - mobiles and tablets
  ---------------------------------------------------
*/

@media only screen and (max-width: 767px) {
  .menu {
    padding: 5px 0px;
    text-align: center;
    background: $light-color;
    min-width: 320px !important;

    &_toggle {
      float: none;
      right: 0;
      border-width: 0px;
      position: absolute;

      &:focus {
        background: $light-color !important;
      }

      &:hover {
        background: $light-color !important;
      }

      .icon-bar {
        background: $black-color !important;
      }
    }

    &_nav {
      li a:hover {
        background: $primary-color !important;
      }

      li.active a {
        background: $primary-color !important;
      }
    }
  }
}
