@import 'styles/shared.scss';

/*
  ------------------------------------------------------------
  Header - defaults
  ------------------------------------------------------------
*/

.header {
    width: 100%;
    height: 80vh;
    
    &-initial {
        height: 100vh;
    }

    transition: height 0.7s;

    background-size: cover;
    background-repeat: no-repeat; 

    &::before {
        content: " ";
        top: 0;
        left: 0;
        z-index: 0;
        width: inherit;
        height: inherit;
        display: block;
        position: absolute;
        background-color: rgba($black-color, 0.65);
    }

    .carousel {
        transition: opacity 0.7s;

        &.carousel-collapsed {
            opacity: 0;
        }

        .carousel-control {
            color: $light-color;
        }

        .carousel-indicators .active {
            li {
                width: 8px;
                height: 8px;
            }

            background-color: $light-color;
        }

        .carousel-indicators li {
            border-color: $light-color;

            width: 8px;
            height: 8px;

            &:hover {
                background-color: $light-color;
            }
        }

        .item {
            width: 100%;
            height: 80vh;
            color: $light-color;
            
            &-inner{
                width: 80%;
                margin: auto;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;                  

                h1 {
                    font-size: 3.5em;
                }

                h3 {
                    font-size: 1.7em;
                }  
            }
        }
    }

    &-welcome {
        background-position: top;
        background-image: url('../../../images/header_welcome.jpg');
    }

    &-accounting {
        background-position: bottom;
        background-image: url('../../../images/header_accounting.jpg');
    }

    &-businessplan {
        background-position: center;
        background-image: url('../../../images/header_businessplan.jpg');
    }
}

/*
  ------------------------------------------------------------
  Header - tablets
  ------------------------------------------------------------
*/

@media only screen and (min-width: 576px) {
    .header {
        .carousel {
            .carousel-indicators .active li {
                width: 12px;
                height: 12px;
            }
    
            .carousel-indicators li {
                width: 12px;
                height: 12px;
            }

            .item {
                &-inner {  
                    h1 {
                        font-size: 4.5em;
                    }
    
                    h3 {
                        font-size: 2.7em;
                    }        
                }
            }
        }

        &-welcome {
            background-position: top;
            background-image: url('../../../images/header_welcome_992.jpg');
        }
    
        &-accounting {
            background-position: bottom;
            background-image: url('../../../images/header_accounting_992.jpg');
        }
    
        &-businessplan {
            background-position: center;
            background-image: url('../../../images/header_businessplan_992.jpg');
        }
    }
}

/*
  ------------------------------------------------------------
  Header - desktops
  ------------------------------------------------------------
*/

@media only screen and (min-width: 992px) {
    .header {
        background-attachment: fixed; 

        .carousel {
            .carousel-control {
                display: none;
            }

            .item {
                &-inner {   
                    width: 100%;    

                    h1 {
                        font-size: 5.9em;
                    }

                    h3 {
                        font-size: 3.0em;
                    }  
                }
            }
        }

        &-welcome {
            background-position: top;
            background-image: url('../../../images/header_welcome_1920.jpg');
        }

        &-accounting {
            background-position: bottom;
            background-image: url('../../../images/header_accounting_1920.jpg');
        }

        &-businessplan {
            background-position: center;
            background-image: url('../../../images/header_businessplan_1920.jpg');
        }
    }
}