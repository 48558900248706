@import 'styles/shared.scss';

.scroll-to-top {
    a {
      display: block;
      width: 40px;
      height: 40px;
      font-size: 20px;
      line-height: 40px;
      color: $white-color;
    }
        
    &-visible {
        opacity : 0.5;
    }

    right: 15px;
    bottom: 15px;
    opacity: 0;
    z-index: 1000;
    position: fixed;
    border-radius: 4px;
    background: #333;
    text-align: center;
    transition: opacity 0.5s;
}
