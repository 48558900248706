@import 'styles/shared.scss';

/*
  ------------------------------------------------------------
  Section - defaults
  ------------------------------------------------------------
*/

.section {
    padding : 15px 0 35px;
    min-height: 600px;
}

/*
  ------------------------------------------------------------
  Section - tablets
  ------------------------------------------------------------
*/

@media only screen and (min-width: 576px) {
    .section {
        padding : 25px 0 45px;
    }
}

/*
  ------------------------------------------------------------
  Section - desktops
  ------------------------------------------------------------
*/

@media only screen and (min-width: 992px) {
    .section {
        padding : 35px 0 55px;
    }
}
