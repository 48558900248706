/*
  ------------------------------------------------------------
  Summary - defaults
  ------------------------------------------------------------
*/

.summary {
    margin: 0 0 5px -10px;
    display: flex;
    font-size: 1.6em;
    justify-content: space-between;
}