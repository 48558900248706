@import 'styles/shared.scss';

/*
  ------------------------------------------------------------
  Policies - defaults
  ------------------------------------------------------------
*/

.policies {
    &_notice {
        margin: 10px;

        &_text {
            margin: 5px;
            font-size: 1.2em;
        }

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &_action {
            margin: 5px;
            background-color: $primary-color;
        }
    }

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1030;
    min-height: 50px;
    color: $black-color;
    background: $light-color;
    box-shadow: 0 0 15px rgba($black-color, 0.2);

    &_dialog_policies {
        font-size: 1.4em;

        .tab-content {
            ol {
                padding: 0 5px 0 15px;
                list-style-type: decimal;
                list-style-position: outside;
                
                li {
                    padding-top: 10px;

                    ol {
                        list-style-type: lower-latin;

                        li {
                            padding-top: 5px;
                        }
                    }
                }
            }
        }

        .nav.nav-tabs {
            display: flex;
            overflow: hidden;
            align-items: center;
            border-bottom: 2px solid #ddd;

            > li {
                flex-shrink: 1;
                margin-top: 8px;
                display: block;
                overflow: hidden;
                margin-bottom: 0;
                color: $primary-color;    
                
                a {
                    width: calc(100%);
                    border: 0;
                    padding: 10px 3px;
                }

                a:link {
                    outline: 0;
                    background-color: transparent;
                }

                a:hover span {
                    text-decoration: underline;
                }

                a:focus span {
                    text-decoration: none;
                }

                span {
                    width: calc(100%);
                    word-break: break-word;
                    vertical-align: top;
                }

                &.active {
                    font-weight: bold;
                    color: $dark-color;

                    a:hover span {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

/*
  ------------------------------------------------------------
  Policies - custom devices
  ------------------------------------------------------------
*/

@media only screen and (min-width: 420px) {
    .policies {
        &_dialog_policies {
            .tab-content {
                ol {
                    padding: 0 5px 0 16px;
                }
            }

            .nav.nav-tabs {
                > li {
                    span {
                        max-width: 70px;
                    }

                    a {
                        padding: 10px 5px;
                    }
                }
            }
        }
    }
}

/*
  ------------------------------------------------------------
  Policies - tablets
  ------------------------------------------------------------
*/

@media only screen and (min-width: 576px) {
    .policies {
        &_dialog_policies {
            .tab-content {
                ol {
                    padding: 0 6px 0 22px;
                }
            }

            .nav.nav-tabs {
                > li {
                    span {
                        max-width: 100px;
                    }

                    a {
                        padding: 10px 10px;
                    }
                }
            }
        }
    }
}

/*
  ---------------------------------------------------
  Policies - desktops
  ---------------------------------------------------
*/

@media only screen and (min-width: 768px) {
    .policies {
        &_notice {
            flex-direction: row;
        }

        &-collapsed {
            min-height: 50px;
            background: $light-color;
        }

        &_dialog_policies {
            .tab-content {
                ol {
                    padding: 0 10px 0 30px;
                }
            }

            .nav.nav-tabs {
                > li {
                    span {
                        max-width: unset;
                    }

                    a {
                        padding: 10px 15px;
                    }
                }
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 90px;
        transition: all 0.7s;
        background: transparent;
        background-color: rgba($light-color, 0.9);
    }
}